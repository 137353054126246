<template>
  <table 
    v-if="widgetInSearch"
    class="table center mb-0 bg-hover-light"
  >
    <tr
      v-on:mouseover="hovering = true"
      v-on:mouseleave="hovering = false"
      class="drag-widget"
    >
      <td class="pl-1" style="width:45px">
        <a
          href="javascript:void(0)"
        >
          <i class="fas fa-grip-vertical text-dark-25 icon-lg mt-2 ml-2"></i>
        </a>
      </td>
      <td class="d-table-cell py-5 px-0 text-center" style="width:45px">
        <PublishedState
          :componentId="data.id"
          updateUri="project_widgets"
          :published="data.published"
        >
        </PublishedState>
      </td>
      <td class="d-table-cell py-5 px-2" style="min-width:300px">
        <span class="d-block font-size-sm">
          <router-link
            tag="a"
            :to="{ name: 'edit_widget', params: { id: data.id }}"
            class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
          >
            {{ calculatedWidgetTitle | stripHTML }}
          </router-link>
        </span>
      </td>
      <td class="d-table-cell py-5 px-2" style="width:200px">
        <span
          class="d-block font-size-sm"
        >
          {{ data.system_widget_type.name }}
        </span>
      </td>
      <td
        class="d-table-cell px-2"
        :class="{ 'py-5' : data.activities.length == 0, 'py-2' : data.activities.length >= 0 }"
         style="width:360px"
      >
        <span class="text-dark-65 text-hover-primary mb-1 font-size-sm">
          {{ calculatedLocationTitle }}
        </span>
        <span
          v-if="data.activities.length > 0"
          class="text-muted d-block font-size-sm"
          style="max-width:200px; overflow-x: clip"
        >
          {{ calculatedLocationSubtitle }}
        </span>
      </td>
      <td class="d-table-cell py-5 px-2" style="width:220px">
        <span
          class="d-block font-size-sm"
        >
          <GenericDatetimeDisplay
            :long="true"
            :date="data.created_at"
            :createdAt="true"
            :lastUpdated="false"
            :relative="false"
          >
          </GenericDatetimeDisplay>
        </span>
      </td>
      <td class="pr-1 pt-3 pb-0 text-right d-table-cell" style="width:200px">
        <div v-if="hovering">
          <router-link
            tag="a"
            :to="{ name: 'edit_widget', params: { id: data.id }}"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            v-b-tooltip.hover.bottom
            :title="$t('SETUP.WIDGETS.EDIT')"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="media/svg/icons/Design/Edit.svg" />
            </span>
          </router-link>
          <a
            href="javascript:void(0)"
            class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
            v-b-tooltip.hover.bottom
            :title="$t('SETUP.WIDGETS.DELETE')"
            @click="deleteWidget"
          >
            <span class="svg-icon svg-icon-md svg-icon-danger">
              <inline-svg src="media/svg/icons/Home/Trash.svg" />
            </span>
          </a>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import { DELETE_PROJECT_WIDGET } from "@/core/services/store/project/project_widgets.module";

import PublishedState from "@/view/components/table-columns/general/PublishedState";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

export default {
  name: "WidgetRow",

  props: {
    data: { type: Object, required: true },
    project: { type: Object, required: true },
    search: { type: String, required: true }
  },

  data() {
    return {
      hovering: false,
      published: null
    }
  },

  components: {
    PublishedState,
    GenericDatetimeDisplay
  },

  methods: {
    deleteWidget: function() {
      let title = this.calculatedWidgetTitle;
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.WIDGET_DELETE_CHALLENGE.TITLE")}`,
        text: title + " " + `${this.$t("SWEETALERTS.WIDGET_DELETE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.WIDGET_DELETE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            projectId: this.data.project_id,
            id: this.data.id
          };
          this.$store.dispatch(DELETE_PROJECT_WIDGET, payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.WIDGET_DELETE_SUCCESS.TITLE")}`,
              text: title + ' ' + `${this.$t("SWEETALERTS.WIDGET_DELETE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.WIDGET_DELETE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      })
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),

    widgetInSearch() {
      let title = this.calculatedWidgetTitle.toLowerCase();
      let activity = this.calculatedActivityPin.toLowerCase();
      let type = this.data.system_widget_type.name.toLowerCase();
      let location = this.calculatedLocationTitle.toLowerCase();

      if (
        title.includes(this.search.toLowerCase()) ||
        activity.includes(this.search.toLowerCase()) ||
        type.includes(this.search.toLowerCase()) ||
        location.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    calculatedPublished: function() {
      if (this.published == null) {
        return this.data.published;
      } else {
        return this.published
      }
    },
    calculatedPublishedClass: function() {
      if (this.calculatedPublished) {
        return "label-success"
      } else {
        return "label-danger"
      }
    },
    calculatedPublishedTitle: function() {
      if (this.calculatedPublished) {
        return `${this.$t("GENERAL.STATUS.PUBLISHED")}`;
      } else {
        return `${this.$t("GENERAL.STATUS.NOT_PUBLISHED")}`
      }
    },
    calculatedWidgetTitle: function() {
      let title = "";
      let usesSystemTitle = [2,4,6,7];
      let usesProvidedTitle = [1,5];

      if (usesSystemTitle.includes(this.data.system_widget_types_id)) {
        title = `${this.$t("SYSTEM.WIDGET_TYPE." + this.data.system_widget_types_id)}`
      } else if (usesProvidedTitle.includes(this.data.system_widget_types_id)) {
        let titleConfig = this.data.project_widget_configs.filter(function( obj ) {
          return obj.name == 'title';
        });
        title = titleConfig[0].value;
      } else if (this.data.system_widget_types_id == 3) {
        if (this.data.poll != undefined) {
          title = this.data.poll.title;
        } else {
          title = "ERROR";
        }
      }

      return title;
    },
    calculatedLocationTitle: function() {
      if (this.data.activities.length == 0) {
        return `${this.$t("SETUP.WIDGETS.LOCATION_HOME")}`
      } else {
        return `${this.$t("SETUP.WIDGETS.LOCATION_PINNED")}`
      }
    },
    calculatedLocationSubtitle: function() {
      if (this.data.activities.length == 1) {
        return this.data.activities[0].title;
      } else {
        return this.data.activities.length + " " + `${this.$t("TABLES.SEGMENT_HEADERS.ACTIVITIES")}`
      }
    },
    calculatedActivityPin: function() {
      if (this.data.activity == null) {
        return "";
      } else {
        return this.data.activity.title;
      }
    }
  }
};
</script>
