<template>
  <div>
    <div class="col-xxl-12 px-0">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div class="card-body">
          <div v-if="loadingComplete && !error.active" class="d-flex justify-content-between">
            <div>
              <h6>{{ $t('SETUP.NAV.WIDGETS') }}</h6>
              <p class="mb-10">{{ $t('SETUP.WIDGETS.DESCRIPTION') }}</p>              
            </div>
            <div>
              <router-link
                to="add_widget"
                tag="a"
                class="btn btn-primary"
              >
                {{ $t('SETUP.WIDGETS.ADD') }}
              </router-link>
            </div>
          </div>

          <b-alert v-if="error.active" show variant="danger">
            {{ error.message }}
          </b-alert>

          <b-spinner
            v-if="loadingComplete == false && error.active == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>

          <div v-if="loadingComplete && !error.active" class="d-flex justify-content-between mb-10">
            <div class="form-group mb-0 col-xl-6 px-0">
              <input
                id="page_table_search"
                v-model="search"
                type="text"
                :placeholder="$t('GENERAL.INPUT.SEARCH')"
                class="form-control form-control-solid h-auto py-3 px-5 w-75"
                :disabled="loadingComplete == false"
              />
            </div>
          </div>

          <div v-if="loadingComplete && !error.active" class="table-responsive">
            <div class="col-xl-12 px-0">
              <div class="table-responsive mb-0">
                <table
                  id="extended_profiling_table"
                  class="table table-head-custom table-vertical center table-hover mt-0"
                  style="table-layout:auto"
                >
                  <thead>
                    <tr>
                      <th class="px-2 py-3" style="width:45px"></th>
                      <th class="px-2 py-3" style="width:45px"></th>
                      <th class="px-2 py-3 text-left" style="min-width:300px">
                        {{ $t('TABLES.WIDGET_HEADERS.NAME') }}
                      </th>
                      <th class="px-2 py-3 text-left" style="width:200px">
                        {{ $t('TABLES.WIDGET_HEADERS.TYPE') }}
                      </th>
                      <th class="px-2 py-3 text-left" style="width:360px">
                        {{ $t('TABLES.WIDGET_HEADERS.LOCATION') }}
                      </th>
                      <th class="px-2 py-3 text-left" style="width:220px">
                        {{ $t('TABLES.WIDGET_HEADERS.CREATED_ON') }}
                      </th>
                      <th class="px-2 py-3 text-right" style="width:200px">
                        {{ $t('TABLES.WIDGET_HEADERS.ACTIONS') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="projectWidgets.length == 0 && loadingComplete">
                      <td class="px-2 text-center" colspan="7">
                        <span class="text-dark-65 font-weight-bold d-block py-3">
                          {{ $t('TABLES.WIDGET_MESSAGES.NO_WIDGETS_TEXT') }}
                          <router-link
                            tag="a"
                            class="text-primary"
                            :to="{ name: 'add_widget' }"
                          >
                            {{ $t('TABLES.WIDGET_MESSAGES.NO_WIDGETS_LINK') }}
                          </router-link>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <draggable
                :list="projectWidgets"
                @end="onEnd"
                handle=".drag-widget"
              >
                <template v-for="(widget, i) in projectWidgets">
                  <WidgetRow
                    :key="i"
                    :data="widget"
                    :search="search"
                    :project="projectInfo"
                  >
                  </WidgetRow>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  POPULATE_PROJECT_WIDGETS,
  UPDATE_PROJECT_WIDGET_ORDER
} from "@/core/services/store/project/project_widgets.module";

import draggable from 'vuedraggable'
import WidgetRow from "@/view/content/projects/WidgetRow.vue";

export default {
  name: "Widgets",

  data() {
    return {
      search: "",
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  components: {
    draggable,
    WidgetRow
  },

  mounted() {
    this.fetchExistingWidgets();
  },

  methods: {
    fetchExistingWidgets() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          position: "ASC",
          systemWidgetType: "with",
          activities: "with",
          projectWidgetConfigs: "with",
          poll: 'with',
          paginate: "false"
        }
      }

      this.$store
        .dispatch(POPULATE_PROJECT_WIDGETS, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the widget list, please check back later or contact the helpdesk";
        });
    },
    onEnd: function() {
      let widgetOrder = [];
      this.projectWidgets.forEach(function (projectWidget) {
        widgetOrder.push(projectWidget.id);
      });

      let payload = {
        projectId: this.projectInfo.id,
        data: {
          project_widgets: widgetOrder
        }
      };

      this.$store.dispatch(UPDATE_PROJECT_WIDGET_ORDER, payload);
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectWidgets"
    ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>
